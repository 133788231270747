.main-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
  height: 64px; /* Fixed height for header */
}

.main-page__tab-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.main-page__tab-buttons {
  background-color: #74bbed;
  border-radius: 6px;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  height: 36px; /* Fixed height for tab buttons */
}

.main-page__tab-button {
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  line-height: 20px;
}

/* Rest of the CSS remains the same */
.main-page__tab-button--active {
  background-color: #5fb7f5;
  color: #fff;
}

.main-page__content-area {
  flex: 1;
  display: flex;
  background-color: #ececec;
}

.main-page__nexus-search {
  display: flex;
  width: 100%;
}

.main-page__sidebar {
  width: 200px;
  padding: 8px;
  background-color: #FFFFFF;
  border-right: 1px solid #E0E0E0;
}

.main-page__course-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  font-size: 13px;
}

.main-page__history-list {
  padding: 0;
  list-style-type: none;
}

.main-page__history-item {
  margin-bottom: 8px;
  background-color: #ececec;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-page__main-content {
  flex: 1;
  padding: 16px;
  min-width: 0; /* Ensures proper flex behavior */
}

.main-page__message-container {
  max-width: 800px;
  margin: 0 auto;
}

.main-page__message {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
}

.main-page__message--user {
  justify-content: flex-end;
}

.main-page__bot-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-page__message-content {
  max-width: 60%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.main-page__message--user .main-page__message-content {
  background-color: #b5e0ff;
}

.main-page__loading {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #666;
}

.main-page__snippet-list {
  margin-top: 20px;
}

.main-page__snippet-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-page__snippet-item:hover,
.main-page__snippet-item--selected {
  background-color: #b5e0ff;
}

.main-page__timestamp {
  display: block;
  color: #666;
  margin-top: 5px;
  font-size: 0.9em;
}

