/* .home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FAFAFA;
}

.home-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
} */

.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ececec;
}

.home-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
  height: 64px; /* Matching MainPage header height */
}

.home-page__main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.home-page__welcome-message {
  text-align: center;
  margin-bottom: 50px;
}

.home-page__welcome-title {
  color: #084a78;
  font-weight: bold;
  font-size: 3.5rem;
  margin-bottom: 15px;
}

.home-page__welcome-subtitle {
  color: #084a78;
  font-size: 1.4rem;
}

.home-page__course-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.home-page__course-item {
  border: 1px solid #5fb7f5;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-page__course-item:hover {
  background-color: #5fb7f5;
  color: white;
}

.home-page__course-name {
  margin-bottom: 10px;
}

.home-page__course-code {
  margin: 0;
}

.home-page__file-upload-area {
  width: 100%;
  max-width: 800px;
  border-top: 1px solid #5fb7f5;
  padding-top: 20px;
}

.home-page__file-upload-title {
  color: #5fb7f5;
  margin-bottom: 20px;
}

.home-page__file-upload-box {
  border: 2px dashed #5fb7f5;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
}


