/* .ai-tutor {
    display: flex;
    justify-content: center;  
    align-items: center;    
    width: 100%;              
    height: 100vh;           
    background-color: #EBE6DA;
  } */
  
.ai-tutor__mind-map {
    max-width: 80%;           /* Scale the image to a maximum of 80% width */
    max-height: 80%;          /* Scale the image to a maximum of 80% height */
    object-fit: contain;      /* Ensure the image retains its aspect ratio */
  }

  /* .ai-tutor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #EBE6DA;
  }
   */
  .ai-tutor svg {
    width: 100%;
    height: 100%;
  }









  
  .ai-tutor {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
  }
  
  .mermaid-container {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
  }
  
  .mermaid {
    width: 100%;
    min-height: 1200px; /* Increased to accommodate larger nodes */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Mermaid specific styles */
  .mermaid .edgeLabel {
    background-color: white !important;
    padding: 2px 4px !important;
    font-size: 12px !important;
    color: #666 !important;
  }
  
  .mermaid .nodeLabel {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px !important;
    line-height: 1.4 !important;
    padding: 8px !important;
  }
  
  /* Topic nodes */
  .mermaid .topic .nodeLabel {
    font-weight: bold !important;
    font-size: 16px !important;
  }
  
  /* Question nodes */
  .mermaid .question .nodeLabel {
    text-align: left !important;
    white-space: normal !important;
    word-wrap: break-word !important;
  }
  
  /* Hover effects */
  .mermaid .node:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .mermaid .edgeLabel:hover {
    background-color: #f0f0f0 !important;
    cursor: pointer;
  }
  
  /* Zoom controls */
  .mermaid-container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  .mermaid-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
  }
  
  .mermaid-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  
  .mermaid-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }