.course-builder__main {
    flex: 1;
    padding: 40px;
    background-color: #FAFAFA;
  }
  
  .course-builder__back-button {
    display: inline-block;
    color: #5fb7f5;
    text-decoration: none;
    margin-bottom: 30px;
    font-weight: 500;
  }
  
  .course-builder__back-button:hover {
    text-decoration: underline;
  }
  
  .course-builder__content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .course-builder__course-section {
    margin-bottom: 40px;
  }
  
  .course-builder__input {
    width: 100%;
    padding: 12px;
    border: 1px solid #5fb7f5;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .course-builder__input--large {
    font-size: 1.2rem;
    padding: 15px;
  }
  
  .course-builder__input:focus {
    border-color: #084a78;
  }
  
  .course-builder__module {
    margin-bottom: 30px;
    padding-left: 20px;
    border-left: 2px solid #5fb7f5;
  }
  
  .course-builder__upload-box {
    margin-top: 15px;
    border: 2px dashed #5fb7f5;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .course-builder__upload-box:hover {
    border-color: #084a78;
  }
  
  .course-builder__upload-box.active {
    border-color: #084a78;
    background-color: rgba(95, 183, 245, 0.1);
  }
  
  .course-builder__file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .course-builder__file-info button {
    background: none;
    border: none;
    color: #5fb7f5;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .course-builder__add-module {
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: 2px solid #5fb7f5;
    color: #5fb7f5;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .course-builder__add-module:hover {
    background-color: #5fb7f5;
    color: white;
  }
  
  .course-builder__actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
  }
  
  .course-builder__button {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .course-builder__button:hover {
    opacity: 0.8;
  }
  
  .course-builder__button--save {
    background: none;
    border: 2px solid #5fb7f5;
    color: #5fb7f5;
  }
  
  .course-builder__button--submit {
    background-color: #5fb7f5;
    border: none;
    color: white;
  }


  .course-builder__module-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .course-builder__input {
    flex: 1;
  }
  
  .course-builder__remove-module {
    background: none;
    border: 2px solid #5fb7f5;
    color: #5fb7f5;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .course-builder__remove-module:hover {
    background-color: #5fb7f5;
    color: white;
  }