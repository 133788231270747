.pdf-viewer__highlight-layer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.pdf-viewer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    font-family: 'Arial', sans-serif;
  }


  .pdf-viewer__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pdf-viewer__buttons {
    display: flex;
    gap: 1rem;
  }
  
  .pdf-viewer__button,
  .pdf-viewer__select {
    padding: 0.5rem 1rem;
    background-color: #5fb7f5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pdf-viewer__select {
    background-color: white;
    color: #5fb7f5;
    border: 1px solid #5fb7f5;
  }
  
  .pdf-viewer__main-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    height: calc(100vh - 60px); /* Adjust based on your header height */
    overflow: auto;
  }
  
  .pdf-viewer__pdf-section {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .pdf-viewer__pdf-container {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pdf-viewer__canvas-container {
    max-width: 100%;
    max-height: calc(100vh - 150px);
    overflow: auto;
  }
  
  .pdf-viewer__canvas {
    max-width: 100%;
    height: auto;
  }


 .pdf-viewer__snippets-section {
  background-color: #FFFFFF;
  border-left: 1px solid #E0E0E0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.pdf-viewer__snippets-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.pdf-viewer__snippet-item {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #F9F9F9;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pdf-viewer__snippets-title {
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.pdf-viewer__snippet-item h4 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #5fb7f5;
}

.pdf-viewer__snippet-item p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  color: #444;
}

.pdf-viewer__snippet-item strong {
  font-weight: 600;
  color: #333;
}

.pdf-viewer__similarity {
  font-size: 14px;
  color: #5fb7f5;
  font-weight: 600;
}
  /*hi*/


  
/* New styles for improved typography */

.pdf-viewer__relation-summary {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  
  .pdf-viewer__relation-summary h4 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .pdf-viewer__relation-summary p {
    font-size: 14px;
    line-height: 1.6;
    color: #444;
  }
  
  .pdf-viewer__snippet-item h4 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #084a78;
  }
  
  .pdf-viewer__snippet-item p {
    margin-bottom: 0.5rem;
  }
  
  .pdf-viewer__snippet-item strong {
    font-weight: 600;
    color: #333;
  }
  
  .pdf-viewer__page-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 0.25rem;
  }
  
  .pdf-viewer__section-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 0.25rem;
  }
  
  .pdf-viewer__position-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 0.25rem;
  }
  
  .pdf-viewer__similarity {
    font-size: 14px;
    color: #5fb7f5;
    font-weight: 600;
  }
  

  

  .pdf-viewer__page-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    gap: 1rem;
    font-size: 14px;
  }
  
  .pdf-viewer__nav-button {
    padding: 0.5rem 1rem;
    background-color: #5fb7f5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .pdf-viewer__nav-button:hover:not(:disabled) {
    background-color: #084a78;
  }
  
  .pdf-viewer__nav-button:disabled {
    background-color: #D3D3D3;
    cursor: not-allowed;
  }
  
  .pdf-viewer__error-message {
    color: #FF0000;
    text-align: center;
    font-size: 16px;
    margin-top: 1rem;
  }