.text-input-container {
  position: fixed;
  bottom: 20px;
  left: 250px; /* Width of the sidebar */
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.text-input-wrapper {
  width: 100%;
  max-width: 800px; /* Match this with the max-width of your chat space */
  padding: 0 40px; /* Match this with the padding of your chat space */
  box-sizing: border-box;
}

.text-input-bar {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  padding: 8px 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.text-input {
  flex-grow: 1;
  border: none;
  font-size: 16px;
  padding: 8px;
  background: transparent;
  outline: none;
}

.submit-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5fb7f5;
  transition: color 0.3s ease;
}

.submit-button:hover {
  color: #8B4F31;
}

.submit-button:focus {
  outline: none;
}

.submit-button svg {
  width: 24px;
  height: 24px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .text-input-container {
    left: 0; /* Full width on smaller screens */
  }
}